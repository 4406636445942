.mm-slideout .header{
display:none;
}
#transrequest_btn{
display:none;
}
.printimg{
display:none;
}
.notrans{
display:none;
}
